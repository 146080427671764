// Here you can add other styles
.redHover:hover {
  color: #e55353;
}
.helpdeskCategoryCard:hover {
  color: #321fdb;
}

.Toastify__toast-icon {
  display: unset !important;
}

.Toastify__toast-body {
  text-align: left !important;
}

.alert-row {
  background-color: rgba(255, 91, 91, 0.616);
}

.dropdown-menu {
  position: revert;
  width: 160px;
}

.react-bootstrap-table th[data-row-selection] {
  width: 70px !important;
}

.expand-cell {
  vertical-align: middle;
}

.row-expansion-style {
  vertical-align: middle;
}

.react-datepicker__input-container input {
  padding: 0.375rem 0.75rem;
  width: 100%;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
